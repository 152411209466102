<template>
  <vs-button @click="printPdf()" color="primary" type="border" class="mb-4 md:mb-0">พิมพ์ใบเสร็จเล็ก (Lq590)</vs-button>
</template>

<script>
  import jsPDF from "jspdf";
  import 'jspdf-autotable'

  const fs = require('fs');
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moment from "moment";
  import {formatNumber, formatNumber2Digit} from "./assets/utils";
  import BAHTTEXT from "./assets/BAHTTEXT";

  export default {
    M04: "BillReport",
    components: {
      jsPDF,
    },
    props: ['member', 'paidLists', 'yearTh', 'imgBase64', 'org', 'imgLogoBase64'],
    data() {
      return {
        // 5 บรรทัด
        left: {x: 35, y: 7},
        center: {x: 107, y: 7},
        right: {x: 180, y: 7},
        signature: {x: 0, y: 0},
        spacing: 94,
      }
    },
    methods: {
      getTaxId(tax_id) {
        if(tax_id.toString().length === 13){
          return tax_id;
        }else{
          return tax_id.substring(0, 13);
        }
      },
      async printPdf() {
        if(localStorage.getItem('paper_x')!=null){
          this.left.x = this.left.x + parseInt(localStorage.getItem('paper_x'));
          this.center.x = this.center.x + parseInt(localStorage.getItem('paper_x'));
          this.right.x = this.right.x + parseInt(localStorage.getItem('paper_x'));
        }
        if(localStorage.getItem('paper_y')!=null){
          this.left.y = this.left.y + parseInt(localStorage.getItem('paper_y'));
          this.center.y = this.center.y + parseInt(localStorage.getItem('paper_y'));
          this.right.y = this.right.y + parseInt(localStorage.getItem('paper_y'));
        }
        let member = this.member;
        let book = this.paidLists;
        let staff = JSON.parse(sessionStorage.getItem('vuex'));
        if(member === null || member === undefined || member === '' || book === null || book === undefined || book === '') {
          this.warningDialog();
        } else {
          this.config = await this.$store.state.config.item;
          this.report(member, book, staff.owner.user.user);
        }
      },
      date(value) {
        let word = value.split(' ');
        return word[0];
      },
      checkData() {
        if (this.bookLists.length === 0) {
          this.warningDialog();
        } else {
          this.generatePDF();
        }
      },
      warningDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'ไม่มีข้อมูลบันทึกการชำระ',
          acceptText: 'ปิด'
        })
      },
      report(member, bookLists, staff) {
        const signature = this.imgBase64;
        const logo = this.imgLogoBase64;
        const org = this.org;
        const doc = new jsPDF({
          orientation: "landscape",
          unit: "mm",
          format: [210, 97]
        });
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.setFont('THSarabunNew', 'normal');
        doc.setFontSize(14);
        let j = 0;
        for (let i = 0; i < bookLists.length; i++) {
          let BA11 = 0;
          let BA12 = 0;
          let BA13 = 0;
          let BA14 = 0;
          let BA15 = 0;
          if (bookLists[i].BA11 !== undefined && bookLists[i].BA11 !== null && bookLists[i].BA11 !== '') {
            BA11 = parseFloat(bookLists[i].BA11);
          }
          if (bookLists[i].BA12 !== undefined && bookLists[i].BA12 !== null && bookLists[i].BA12 !== '') {
            BA12 = parseFloat(bookLists[i].BA12);
          }
          if (bookLists[i].BA13 !== undefined && bookLists[i].BA13 !== null && bookLists[i].BA13 !== '' && bookLists[i].BA13 !== 'NaN') {
            BA13 = parseFloat(bookLists[i].BA13);
          }
          if (bookLists[i].BA14 !== undefined && bookLists[i].BA14 !== null && bookLists[i].BA14 !== '') {
            BA14 = parseFloat(bookLists[i].BA14);
          }
          if (bookLists[i].BA15 !== undefined && bookLists[i].BA15 !== null && bookLists[i].BA15 !== '') {
            BA15 = parseFloat(bookLists[i].BA15);
          }
          let sum = BA11 + BA12 + BA13 + BA15;
          sum = parseFloat(sum);
          let line = j;
          //LEFT
          doc.setFontSize(14);
          doc.text(org.Org01, this.left.x, (this.left.y + j * this.spacing) - line);
          doc.setFontSize(12);
          doc.text(org.Org03, this.left.x, (this.left.y + j * this.spacing) - line + 5);
          doc.text(bookLists[i].B02, this.left.x, (this.left.y + j * this.spacing) - line + 5 * 2);
          doc.text(bookLists[i].CB04, this.left.x, (this.left.y + j * this.spacing) - line + 5 * 3);
          doc.text(bookLists[i].B03, this.left.x + 40, (this.left.y + j * this.spacing) - line + 5 * 3);
          doc.text(member.name.toString().toString(), this.left.x - 13, (this.left.y + j * this.spacing) - line + 5 * 4.3);
          doc.text(member.meter.toString().toString(), this.left.x + 40, (this.left.y + j * this.spacing) - line + 5 * 4);
          doc.text(member.address.toString(), this.left.x - 10, (this.left.y + j * this.spacing) - line + 5 * 5);
          doc.text(member.road.toString(), this.left.x - 10, (this.left.y + j * this.spacing) - line + 5 * 6 - 2);
          doc.text(member.area.toString().toString(), this.left.x + 40, (this.left.y + j * this.spacing) - line + 5 * 5.5);
          doc.setFontSize(10);
          doc.text(bookLists[i].B17.toString(), this.left.x, (this.left.y + j * this.spacing) - line + 5 * 8);
          doc.text(bookLists[i].B19.toString(), this.left.x, (this.left.y + j * this.spacing) - line + 5 * 9);
          doc.setFontSize(12);
          doc.text(formatNumber(bookLists[i].BA12), this.left.x + 5, (this.left.y + j * this.spacing) - line + 5 * 10.2);
          doc.text('ภาษีฯ               '+formatNumber(bookLists[i].BA13) +'         บาท', this.left.x - 15, (this.left.y + j * this.spacing) - line + 5 * 11.4);
          doc.text('ค่าบำบัดฯ '+bookLists[i].BA14.toString() +'บาท '+'ค่าขยะฯ '+bookLists[i].BA15.toString() +'บาท', this.left.x - 15, (this.left.y + j * this.spacing) - line + 5 * 12.4);
          doc.text(BAHTTEXT(sum), this.left.x - 12, (this.left.y + j * this.spacing) - line + 5 * 13.4);
          doc.text(bookLists[i].B18.toString(), this.left.x + 15, (this.left.y + j * this.spacing) - line + 5 * 8);
          doc.text(bookLists[i].B20.toString(), this.left.x + 15, (this.left.y + j * this.spacing) - line + 5 * 9);
          doc.text(bookLists[i].B21.toString(), this.left.x + 30, (this.left.y + j * this.spacing) - line + 5 * 8.5);
          doc.text(formatNumber(bookLists[i].BA11), this.left.x + 40, (this.left.y + j * this.spacing) - line + 5 * 8.5);
          doc.setFontSize(16);
          doc.text(formatNumber(sum.toString()), this.left.x + 35, (this.left.y + j * this.spacing) - line + 5 * 12);
          if(signature !== '' && signature !== null) {
            doc.addImage(signature, 'PNG',  this.left.x + 15,(this.left.y + j * this.spacing) - line + 5 * 13 + 2, 20, 8);
          }
          if(logo !== '' && logo !== null) {
            doc.addImage(logo, 'PNG',  this.left.x + 49,(this.left.y - 6 + j * this.spacing), 15, 15);
          }
          if(logo !== '' && logo !== null) {
            doc.addImage(logo, 'PNG',  this.center.x + 51,(this.left.y - 6 + j * this.spacing), 15, 15);
          }
          //CENTER
          doc.setFontSize(14);
          doc.text(org.Org01, this.center.x -10, (this.center.y + j * this.spacing) - line);
          doc.setFontSize(12);
          doc.text(org.Org03, this.center.x -10, (this.center.y + j * this.spacing) - line + 5);
          doc.text(bookLists[i].B02, this.center.x, (this.center.y + j * this.spacing) - line + 5 * 2);
          doc.text(bookLists[i].CB04, this.center.x, (this.center.y + j * this.spacing) - line + 5 * 3);
          doc.text(this.getTaxId(org.tax_id), this.center.x + 35, (this.center.y + j * this.spacing) - line + 5 * 2);
          doc.text(bookLists[i].B03, this.center.x + 40, (this.center.y + j * this.spacing) - line + 5 * 3);
          doc.text(member.name.toString(), this.center.x - 13, (this.center.y + j * this.spacing) - line + 5 * 4.3);
          doc.text(member.meter.toString(), this.center.x + 40, (this.center.y + j * this.spacing) - line + 5 * 4.5);
          doc.text(member.address.toString(), this.center.x - 10, (this.center.y + j * this.spacing) - line + 5 * 5);
          doc.text(member.road.toString(), this.center.x - 10, (this.center.y + j * this.spacing) - line + 5 * 6 - 2);
          doc.text(member.area.toString(), this.center.x + 40, (this.center.y + j * this.spacing) - line + 5 * 5.5);
          doc.setFontSize(10);
          doc.text(bookLists[i].B17.toString(), this.center.x, (this.center.y + j * this.spacing) - line + 5 * 8);
          doc.text(bookLists[i].B19.toString(), this.center.x, (this.center.y + j * this.spacing) - line + 5 * 9);
          doc.setFontSize(12);
          doc.text(formatNumber(bookLists[i].BA12), this.center.x, (this.center.y + j * this.spacing) - line + 5 * 10.2);
          doc.text('ภาษีฯ          '+formatNumber(bookLists[i].BA13), this.center.x - 15, (this.center.y + j * this.spacing) - line + 5 * 11.4);
          doc.text('ค่าบำบัดฯ '+bookLists[i].BA14.toString() +'บาท '+'ค่าขยะฯ '+bookLists[i].BA15.toString() +'บาท', this.center.x - 15, (this.center.y + j * this.spacing) - line + 5 * 12.4);
          doc.text(BAHTTEXT(sum), this.center.x - 15, (this.center.y + j * this.spacing) - line + 5 * 13.4);
          doc.text(bookLists[i].B18.toString(), this.center.x + 15, (this.center.y + j * this.spacing) - line + 5 * 8);
          doc.text(bookLists[i].B20.toString(), this.center.x + 15, (this.center.y + j * this.spacing) - line + 5 * 9);
          doc.text(bookLists[i].B21.toString(), this.center.x + 30, (this.center.y + j * this.spacing) - line + 5 * 8.5);
          doc.text(formatNumber(bookLists[i].BA11), this.center.x + 45, (this.center.y + j * this.spacing) - line + 5 * 8.5);
          doc.setFontSize(16);
          doc.text(formatNumber(sum.toString()), this.center.x + 40, (this.center.y + j * this.spacing) - line + 5 * 12);
          if(signature !== '' && signature !== null) {
            doc.addImage(signature, 'PNG',  this.center.x - 10, (this.center.y + j * this.spacing) - line + 5 * 13 + 2, 20, 8);
          }
          doc.setFontSize(12);
          doc.text(bookLists[i].B09.toString(),  this.center.x + 35, (this.center.y + j * this.spacing) - line + 5 * 14.5);
          if(bookLists[i].B24 !== undefined){
            doc.text('วันที่รับเงิน '+bookLists[i].B24.toString(),  this.center.x + 30, (this.center.y + j * this.spacing)  - line + 5 * 13 + 2);
            doc.text('วันที่ '+bookLists[i].B24.toString(), this.left.x + 15, (this.left.y + j * this.spacing) - line + 5 * 2);
          }

          //RIGHT
          doc.setFontSize(12);
          doc.text(bookLists[i].CB04.toString(), this.right.x, (this.right.y + j * this.spacing) - line + 5 * 2);
          doc.text(bookLists[i].B03.toString(), this.right.x, (this.right.y + j * this.spacing) - line + 5 * 3.3);
          doc.text(bookLists[i].B22.toString(), this.right.x, (this.right.y + j * this.spacing) - line + 5 * 4.5);
          doc.text(member.name.toString().toString(), this.right.x - 10, (this.right.y + j * this.spacing) - line + 5 * 6);
          doc.text(member.address.toString(), this.right.x - 10, (this.right.y + j * this.spacing) - line + 5 * 7);
          doc.text(member.road.toString(), this.right.x - 10, (this.right.y + j * this.spacing) - line + 5 * 8);
          doc.setFontSize(16);
          doc.text(formatNumber(sum.toString()), this.right.x, (this.right.y + j * this.spacing) - line + 5 * 12.5);
          doc.setFontSize(12);
          // doc.text(bookLists[i].B24.toString(), this.right.x, (this.right.y + j * this.spacing) - line + 5 * 15.5);
          j = j + 1;
          if ((i + 1) % 1 === 0) {
            if (i + 1 === bookLists.length) break;
            doc.addPage();
            j = 0;
          }
        }
        // doc.setProperties({
        //   title: `รายงานใบเสร็จค่าน้ำประปา-${new Date().toLocaleString()}`
        // });
        // doc.output("dataurlnewwindow");
        doc.save(`ใบเสร็จ-${new Date().toLocaleString()}.pdf`);
      },
    }
  }
</script>
