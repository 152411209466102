<template>
  <vs-button color="primary" type="border" class="mb-4 md:mb-0" @click="printPdf()">{{title}}</vs-button>
</template>

<script>
  import moduleConfig from "@/store/config/moduleConfig.js"
  import jsPDF from "jspdf";
  import 'jspdf-autotable'
  const fs = require('fs');
  import { font } from "./assets/THSarabunNew-normal";
  import { font_bold } from "./assets/THSarabunNew-bold";
  import moment from "moment";
  import {formatNumber, formatNumber2Digit} from "./assets/utils";
  import BAHTTEXT from "./assets/BAHTTEXT";

  export default {
    props: ['paidLists', 'billConfig', 'area', 'member',  'org', 'status', 'imgBase64', 'imgLogoBase64', 'orientation', 'title', 'size'],
    components: {
      jsPDF,
    },
    data() {
      return {
        config: '',
        thmonth: ['', 'มกราคม', 'กุมภาพันธ์', 'มีนาคม',
          'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน',
          'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
      }
    },
    methods: {
      getTaxId(tax_id) {
        if(tax_id.toString().length === 13){
          return tax_id;
        }else{
          return tax_id.substring(0, 13);
        }
      },
      formatTextDate(text){
        if(text !== '' && text !== undefined){
          const date = text.split('/');
          let year = ''
          if(date[2].toString().length === 11)
            year = parseInt(date[2]) + 2543;
          else
            year = parseInt(date[2]) + 543;
          return date[0] + '/' + date[1] + '/' + year;
        }else{
          return '-'
        }
      },
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      async printPdf() {
        let member = this.member;
        let book = this.paidLists;
        let staff = JSON.parse(localStorage.getItem("userInfo"));
        if(member === null || member === undefined || member === '' || book === null || book === undefined || book === '') {
          this.warningDialog();
        } else {
          this.config = await this.$store.state.config.item;
          this.generatePDF(book, member , staff);
        }
      },
      generatePDF(bookLists, member, staff) {
        const doc = new jsPDF({
          orientation: this.orientation,
          unit: "mm",
          format: this.size
        });
        const org = this.org;
        const tax = this.getTaxId(org.tax_id);
        const district = org.Org01;
        const address = org.Org03;
        const month = bookLists[0].CB04;
        const id_bill = bookLists[0].B01;
        const area = this.area;
        const date = this.currentThaiDate()
        const signature = this.imgBase64;
        const logo = this.imgLogoBase64;
        let sumUnit = 0;
        let sumPrapa = 0;
        let sumService = 0;
        let sumVat = 0;
        let sumGarbage = 0;
        let sum = 0;
        let array = [];
        let sum_payment_footer = 0;
        var totalPagesExp = '{total_pages_count_string}';
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        for (let i = 0; i < bookLists.length; i++) {
          const data = bookLists[i];
          const bookList = bookLists[i];
          let BA11 = 0;
          let BA12 = 0;
          let BA13 = 0;
          let BA14 = 0;
          let BA15 = 0;
          let sumBA = 0;
          if (data.BA11 !== undefined && data.BA11 !== null && data.BA11 !== '' && data.BA11 !== 'NaN') {
            BA11 = parseFloat(data.BA11);
            sumPrapa += parseFloat(data.BA11);
          }
          if (data.BA12 !== undefined && data.BA12 !== null && data.BA12 !== '' && data.BA12 !== 'NaN') {
            BA12 = parseFloat(data.BA12);
            sumService += parseFloat(data.BA12);
          }
          if (data.BA13 !== undefined && data.BA13 !== null && data.BA13 !== '' && data.BA13 !== 'NaN') {
            BA13 = parseFloat(data.BA13);
            sumVat += parseFloat(data.BA13);
          }
          if (data.BA14 !== undefined && data.BA14 !== null && data.BA14 !== '' && data.BA14 !== 'NaN') {
            BA14 = parseFloat(data.BA14);
          }
          if (data.BA15 !== undefined && data.BA15 !== null && data.BA15 !== '' && data.BA15 !== 'NaN') {
            BA15 = parseFloat(data.BA15);
            sumGarbage += parseFloat(data.BA15);
          }
          if (data.B21 !== undefined && data.B21 !== null && data.B21 !== '' && data.B21 !== 'NaN') {
            sumUnit += parseInt(data.B21);
          }
          sumBA = BA11 + BA12 + BA13 + BA14 + BA15;
          sum += sumBA;
          let dateNow = moment(data.B24).format('DD/MM/YYYY');
          let formatDate = '';
          let day;
          let month;
          let yearTh;
          if (dateNow === 'Invalid date') {
            let dateArr = data.B24.split(' ');
            let dateArr0 = dateArr[0].split('/');
            yearTh = parseInt(dateArr0[2]) + 543;
            day = parseInt(dateArr0[0]);
            month = this.thmonth[parseInt(dateArr0[1])];
          } else {
            let dateArr0 = dateNow.split('/');
            yearTh = parseInt(dateArr0[2]) + 543;
            day = parseInt(dateArr0[0])
            month = this.thmonth[parseInt(dateArr0[1])];
          }

          let status_bill = 'ค้างจ่าย';
          let sum_payment = 0;
          if (data.B10 === '1') {
            status_bill = 'จ่ายแล้ว';
            sum_payment = sumBA;
            formatDate = day + ' ' + month + ' ' + yearTh;
          }
          sum_payment_footer += sum_payment;
          let B22 = ''
          if(data.B22 !== '')
            B22 = data.B22
          array.push([
            data.B20,
            data.B18,
            data.B21,
            formatNumber2Digit(BA11),
            formatNumber2Digit(BA12),
            formatNumber2Digit(BA14),
            formatNumber2Digit(BA15),
            formatNumber2Digit(BA13),
            formatNumber(sumBA)
          ]);
          array.push([
            data.B19,
            data.B17,
            {content: BAHTTEXT(sumBA) , colSpan: 6, styles: { halign: 'center'}},
          ]);
          doc.autoTable({
            showHead: 'everyPage',
            showFoot: 'everyPage',
            styles: {font: 'THSarabunNew', fontSize: 14, textColor: 0},
            margin: {left: 10, top: 70, right: 10},
            headStyles: {fillColor: [255, 255, 255], textColor: 0, lineColor: 180, lineWidth: 0.1 , fontSize: 12 , halign: 'center'},
            theme: 'grid',
            columnStyles: {
              0: {cellWidth: 25, halign: 'center'},
              1: {cellWidth: 25, halign: 'center'},
              2: {cellWidth: 20, halign: 'center'},
              3: {cellWidth: 20, halign: 'center'},
              4: {cellWidth: 20, halign: 'center'},
              5: {cellWidth: 20, halign: 'center'},
              6: {cellWidth: 15, halign: 'center'},
              7: {cellWidth: 15, halign: 'center'},
              8: {cellWidth: 20, halign: 'center'},
            },
            head: [
              [
                'จดครั้งหลัง',
                'จดครั้งก่อน',
                'หน่วยที่ใช้',
                'ค่าน้ำ',
                'ค่ารักษามาตร',
                'บำบัดฯ',
                'ค่าขยะ',
                'ภาษี',
                'รวมเป็นเงิน',
              ]
            ],
            body: array,
            didDrawPage: function (data) {
              // Header
              doc.setFont('THSarabunNew', 'bold');
              doc.setFontSize(16);
              doc.text('ใบเสร็จรับเงินค่าน้ำประปา/ใบกำกับภาษี', doc.internal.pageSize.getWidth() / 2, 15, {align: 'center'});
              doc.text(org.Org01, doc.internal.pageSize.getWidth() / 2, 22, {align: 'center'});
              doc.setFontSize(14);
              doc.text(address, doc.internal.pageSize.getWidth() / 2, 29, {align: 'center'});
              doc.text('เลขประจำตัวผู้เสียภาษี '+ tax, doc.internal.pageSize.getWidth() / 2, 36, {align: 'center'});
              doc.text('ค่าน้ำประจำเดือน     '+bookList.CB04, 10, 43);
              doc.text('ได้รับเงินจาก     '+member.name, 10, 50);
              doc.text('ที่อยู่     '+member.address+ ' '+member.road, 10, 57);
              doc.text('เลขประจำมาตร     '+member.meter_no, 10, 64);
              doc.text('เบอร์โทร     '+member.telephone, 90, 64);
              doc.text('ใบเสร็จรับเงินเลขที่     '+bookList.B22, 140, 43);
              doc.text('เลขที่ผู้ใช้น้ำ     '+member.member_no, 140, 50);
              if(signature !== '' && signature !== null) {
                doc.addImage(signature, 'PNG',  35,110, 20, 10);
              }
              doc.text('หัวหน้าหน่วยงานคลัง', 32, 125);
              doc.text(staff.userFullname, 132, 120);
              doc.text('พนักงานเก็บเงิน', 135, 125);
              if(bookList.B24 !== undefined)
              doc.text('วันที่รับเงิน '+bookList.B24, 130, 130);
              if(logo !== '' && logo !== null) {
                doc.addImage(logo, 'PNG',  45,10, 20, 20);
              }
              doc.setFont('THSarabunNew', 'normal');
            },
          });
          array = []
          if (i + 1 === bookLists.length) break;
          doc.addPage();
        }
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp);
        }
        doc.save(`ป.17ค่าน้ำ-` + moment().format('DD-MM-YYYY') + '.pdf');
        // doc.setProperties({
        //   title: `report${new Date().toLocaleString()}`
        // });
        // doc.output("dataurlnewwindow");
      },
      report(user, book, staff) {
        const doc = new jsPDF({format: [279, 210], unit:'mm', orientation: 'portrait',});
        const config = this.config;
        const bookLists = book;
        let array = [];
        for (let i = 0; i < bookLists.length; i++) {
          let BA11 = 0;
          let BA12 = 0;
          let BA13 = 0;
          let BA14 = 0;
          let BA15 = 0;
          if (bookLists[i].BA11 !== undefined && bookLists[i].BA11 !== null && bookLists[i].BA11 !== '') {
            BA11 = parseFloat(bookLists[i].BA11);
          }
          if (bookLists[i].BA12 !== undefined && bookLists[i].BA12 !== null && bookLists[i].BA12 !== '') {
            BA12 = parseFloat(bookLists[i].BA12);
          }
          if (bookLists[i].BA13 !== undefined && bookLists[i].BA13 !== null && bookLists[i].BA13 !== '' && bookLists[i].BA13 !== '0') {
            BA13 = parseFloat(bookLists[i].BA13);
          }
          if (bookLists[i].BA14 !== undefined && bookLists[i].BA14 !== null && bookLists[i].BA14 !== '') {
            BA14 = parseFloat(bookLists[i].BA14);
          }
          if (bookLists[i].BA15 !== undefined && bookLists[i].BA15 !== null && bookLists[i].BA15 !== '') {
            BA15 = parseFloat(bookLists[i].BA15);
          }
          const sum = BA11 + BA12 + BA13 + BA14 + BA15;
          const date = bookLists[i].CB04.split('-');
          array.push([bookLists[i].B01, date[0] + ' ' + date[1], sum.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })])
        }
        const dateNow = moment().format('DD/MM/YYYY');
        const dateSplit = dateNow.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        const date = dateSplit[0] + '/' + dateSplit[1] + '/' + year;
        array.push([{ content: 'ผู้รับเงิน', styles: { halign: 'left'} },{ content: staff.userFullname, styles: { halign: 'left'} }]);
        array.push([{ content: 'วันที่รับเงิน', styles: { halign: 'left'} },{ content: date, styles: { halign: 'left'} }]);
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.autoTable({
          styles: {font: 'THSarabunNew', fontSize: 14},
          margin: {left: 8, top: 50, bottom: 15},
          theme: 'plain',
          head: [['เลขที่บิล', 'ค่าน้ำประจำเดือน', 'จำนวนเงิน']],
          body: array,
          didDrawPage: function (data) {
            // Header
            doc.setFont('THSarabunNew', 'bold');
            doc.setFontSize(16);
            doc.text(config.ReceiveHeader, doc.internal.pageSize.getWidth() / 2, 22, {align: 'center'});
            doc.text('*** ' + config.Org01 + ' ***', doc.internal.pageSize.getWidth() / 2, 28, {align: 'center'});
            doc.text(config.Org03 + ' ' + config.Org04, doc.internal.pageSize.getWidth() / 2, 34, {align: 'center'});
            doc.setFont('THSarabunNew', 'normal');
            doc.setFontSize(14);
            doc.text('เลขที่ผู้ใช้น้ำ', 10, 41);
            doc.text(member.M01, 30, 41);
            doc.text('ชื่อ', 55, 41);
            doc.text(member.M04, 65, 41);
            doc.text('เขตที่', 10, 46);
            doc.text(member.M02, 30, 46);
            doc.text('ที่อยู่', 55, 46);
            doc.text(member.M05 + ' ' + member.M06, 65, 46);
          },
        });
        doc.setProperties({
          title: `report${new Date().toLocaleString()}`
        });
        doc.output("dataurlnewwindow");
      },
      warningDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'ไม่มีข้อมูลบันทึกการชำระ',
          acceptText: 'ปิด'
        })
      },
    },
    async created() {
      if (!moduleConfig.isRegistered) {
        this.$store.registerModule('config', moduleConfig)
        moduleConfig.isRegistered = true
      }
      await this.$store.dispatch("config/fetchDataItem");
    }
  }
</script>
