<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card class="mb-4">
        <marquee-text class="pl-24" :repeat="20" :duration="50">
          <h3>{{notice}}</h3>
        </marquee-text>
      </vx-card>
      <vx-card class="mb-8">
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-radio v-model="radioBtn" vs-value="01">
              <span>เลขทะเบียนผู้ใช้น้ำ</span>
            </vs-radio>
            <vs-input v-model="inputNumber" @keydown="onKeydown($event)"
                      style="width: 170px; display: inline-block; margin-left: 10px" :disabled="disable01"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-radio v-model="radioBtn" vs-value="02">
              <span>บ้านเลขที่</span>
            </vs-radio>
            <vs-input v-model="villageNo" @keydown="onKeydown($event)" style="display: inline-block; margin-left: 10px"
                      :disabled="disable02"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-radio v-model="radioBtn" vs-value="03">
              <span>เบอร์โทร</span>
            </vs-radio>
            <vs-input v-model="telNumber" @keydown="onKeydown($event)" style="display: inline-block; margin-left: 10px"
                      :disabled="disable03"/>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-1/1 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-3/5 w-full">
                <span class="mr-6">รายชื่อ</span>
                <v-select :options="memberOpt" :clearable="false" @input="setMember" v-model="selectedMember"
                          class="mb-4 md:mb-0" style="display: inline-block; width: 90%;">
                  <template #no-options="{memberOpt}">
                    ไม่พบข้อมูล
                  </template>
                </v-select>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
      <vx-card class="mb-8">
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ชื่อ - นามสกุล</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="member.name" readonly/>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>เลขที่ผู้ใช้น้ำ</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="member.member_no" readonly/>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>หมายเลขมาตร</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="member.meter" readonly/>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ที่อยู่</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="member.address" readonly/>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ซอย/ถนน</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="member.road" readonly/>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>เบอร์โทร</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="member.telephone" readonly/>
              </div>
            </div>
          </div>
        </div>
        <span class="m-4">รายการที่ค้างชำระ</span>
        <vs-table noDataText="ไม่พบข้อมูล" :data="bookLists" class="table-custom mt-5 mb-5">
          <template slot="thead">
            <vs-th>ลำดับที่</vs-th>
            <vs-th>เดือน</vs-th>
            <vs-th>ปี</vs-th>
            <vs-th>เลขที่ใบแจ้งหนี้</vs-th>
            <vs-th>ค่าน้ำประปา</vs-th>
            <vs-th>ค่าบริการายเดือน</vs-th>
            <vs-th>ค่าบำบัดฯ</vs-th>
            <vs-th>ภาษีมูลค่าเพิ่ม</vs-th>
            <vs-th>ค่าขยะ</vs-th>
            <vs-th>รวม</vs-th>
            <vs-th>ชำระ</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

              <vs-td>
                <p class="product-name font-medium">{{ indextr + 1 }}</p>
              </vs-td>

              <vs-td>
                <p class="product-name font-medium">{{ splitDate(tr.CB04, 'month') }}</p>
              </vs-td>

              <vs-td>
                <p class="product-name font-medium">{{ splitDate(tr.CB04, 'year') }}</p>
              </vs-td>

              <vs-td>
                <p class="product-name font-medium">{{ tr.B22 }}</p>
              </vs-td>

              <vs-td>
                <p class="product-name font-medium">{{ typeOfNumber(tr.BA11) }}</p>
              </vs-td>

              <vs-td>
                <p class="product-name font-medium">{{ typeOfNumber(tr.BA12) }}</p>
              </vs-td>

              <vs-td>
                <p class="product-name font-medium">{{ typeOfNumber(tr.BA14) }}</p>
              </vs-td>

              <vs-td>
                <p class="product-name font-medium">{{ typeOfNumber(tr.BA13) }}</p>
              </vs-td>

              <vs-td>
                <p class="product-name font-medium">{{ typeOfNumber(tr.BA15) }}</p>
              </vs-td>

              <vs-td>
                <p class="product-name font-medium">{{ parseFloat(sumTable[indextr]).toLocaleString("en-US",
                  {maximumFractionDigits: 2, minimumFractionDigits: 2}) }}</p>
              </vs-td>

              <vs-td>
                <vs-checkbox v-model="checkBox[indextr]" @change="calculateBA(indextr)"></vs-checkbox>
              </vs-td>

            </vs-tr>
          </template>
        </vs-table>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ยอดเงินชำระ</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <div class="vx-row">
                  <div class="vx-col sm:w-2/3 w-full">
                    <vs-input class="w-full" v-model="sumCheckbox" readonly/>
                  </div>
                  <div class="vx-col sm:w-1/3 w-full">
                    <span>บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ชำระ</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <div class="vx-row">
                  <div class="vx-col sm:w-2/3 w-full">
                    <vs-input v-validate="'required'" class="w-full" name="money" v-model="money"/>
                  </div>
                  <div class="vx-col sm:w-1/3 w-full">
                    <span>บาท</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col md:w-1/2 sm:w-1/3 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ประเภทการชำระ</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select v-validate="'required'" name="paybys" @input="setPaybys" :options="paybys" :clearable="false"
                          v-model="selectedPayby" class="mb-4 md:mb-0"/>
                <span class="text-danger text-sm" v-show="errors.has('paybys')">กรุณาเลือกประเภทการชำระ</span>
              </div>
            </div>
          </div>
          <div class="vx-col md:w-1/2 sm:w-1/3 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>เลขที่บัญชี/เลขที่เช็ค</span>
                <span class="text-red align-text-bottom" v-if="!disableBank">*</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="bankNumber" :disabled="disableBank" v-validate="'required'"
                          name="bankNumber"/>
                <span class="text-danger text-sm" v-show="errors.has('bankNumber')" v-if="!disableBank">กรุณากรอกเลขที่บัญชี/เลขที่เช็ค</span>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-12">
          <div class="vx-col md:w-1/2 sm:w-1/3 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/5 w-full">
                <span>เลขที่ใบเสร็จ</span>
                <span class="text-red align-text-bottom">*</span>
              </div>
              <div class="vx-col sm:w-2/5 w-full">
                <vs-input class="w-full" v-model="number" placeholder="เลขที่ใบเสร็จ (ตัวเลขเท่านั้น)"/>
              </div>
              <div class="vx-col sm:w-2/5 w-full">
                <vs-button type="filled" color="primary" @click="createNumber()">สร้างเลขที่ใบเสร็จ</vs-button>
              </div>
            </div>
          </div>
          <div class="vx-col md:w-1/2 sm:w-1/3 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/4 w-full">
                <span>กระดาษ</span>
              </div>
              <div class="vx-col sm:w-1/4 w-full">
                <vs-input-number min="-100" :step="1" label="ซ้าย ขวา:" v-model="x"/>
              </div>
              <div class="vx-col sm:w-1/4 w-full">
                <vs-input-number min="-100" :step="1" label="บน ล่าง:" v-model="y"/>
              </div>
              <div class="vx-col sm:w-1/4 w-full">
                <vs-button color="primary" @click="savePaperSetup()">บันทึก</vs-button>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5 w-full">
          <div class="vx-col w-full">
            <div class="vx-row">
              <PDFReport :paidLists="paidList" :imgBase64="imgSignBase64" :imgLogoBase64="imgLogoBase64" :org="org"
                         class="ml-4 mr-4" :member="member" :orientation="'landscape'"
                         :title="'พิมพ์ใบแทนใบเสร็จ(แนวนอน)'" :size="[140, 210]"/>
              <PDFReport :paidLists="paidList" :imgBase64="imgSignBase64" :imgLogoBase64="imgLogoBase64" :org="org"
                         class="ml-4 mr-4" :member="member" :orientation="'portrait'"
                         :title="'พิมพ์ใบแทนใบเสร็จ(แนวตั้ง)'" :size="[210, 210]"/>
              <ReceiveBillReport :yearTh="yearTh" :paidLists="paidList" :imgBase64="imgSignBase64"
                                 :imgLogoBase64="imgLogoBase64" :org="org" :member="member"
                                 class="mr-4"/>
              <ReceiveBillReport590 :yearTh="yearTh" :paidLists="paidList" :imgBase64="imgSignBase64"
                                 :imgLogoBase64="imgLogoBase64" :org="org" :member="member"
                                 class="mr-4"/>
              <vs-button color="primary" @click="submitPayment()">บันทึกการชำระ</vs-button>
            </div>
          </div>
        </div>
        <span v-if="paidList.length > 0" class="m-4">รายการที่ชำระเงิน</span>
        <vs-table v-if="paidList.length > 0" ref="table" noDataText="ไม่พบข้อมูล" :data="paidList"
                  class="table-custom mt-5">
          <template slot="thead">
            <vs-th>ลำดับที่</vs-th>
            <vs-th>รอบบิล</vs-th>
            <vs-th>วันที่ชำระเงิน</vs-th>
            <vs-th>ผู้รับเงิน</vs-th>
            <vs-th>เลขที่ใบเสร็จ</vs-th>
            <vs-th>รวมเงิน</vs-th>
          </template>
          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
              <vs-td>
                <p class="product-name font-medium">{{ index + 1 }}</p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium">{{ tr.CB04 }}</p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium">{{ tr.B24 }}</p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium">{{ tr.B09 }}</p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium">{{ tr.B02 || '-' }}</p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium">{{ tr.sum || '-' }}</p>
              </vs-td>
            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>

    </div>
  </div>
</template>

<script>
  import moduleConfig from "@/store/config/moduleConfig";
  import moment from "moment";
  import vSelect from 'vue-select';
  import moduleMember from "@/store/member/moduleMember";
  import moduleBookList from "@/store/booklist/moduleBookList";
  import modulePayby from "@/store/payby/modulePayby";
  import PDFReport from '../pdf/PaymentReport'
  import ReceiveBillReport from '../pdf/ReceiveBillReport'
  import ReceiveBillReport590 from '../pdf/ReceiveBillReport590'
  import {formatNumber2Digit} from "../pdf/assets/utils";

  export default {
    components: {
      vSelect,
      PDFReport,
      ReceiveBillReport,
      ReceiveBillReport590
    },
    data() {
      return {
        x: 0,
        y: 0,
        number: 0,
        paidList: [],
        radioBtn: '01',
        date: '',
        inputNumber: '',
        villageNo: '',
        telNumber: '',
        bookLists: [],
        checkBox: [],
        memberOpt: [],
        selectedMember: {label: 'ไม่พบข้อมูล', value: '', index: '', data: ''},
        selectedPayby: {label: '', value: ''},
        member: {
          _id: '',
          name: '',
          member_no: '',
          meter: '',
          address: '',
          road: '',
          telephone: '',
          meter_no: ''
        },
        sumCheckbox: '',
        sumTable: [],
        userAll: [],
        indexMember: 0,
        sum: '',
        money: '',
        change: '',
        typePayment: '',
        disable01: false,
        disable02: true,
        disable03: true,
        thmonth: ['', 'มกราคม', 'กุมภาพันธ์', 'มีนาคม',
          'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน',
          'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
        payBySelect: [],
        bankNumber: '',
        disableBank: true,
        yearTh: '',
        imgSignBase64: '',
        imgLogoBase64: '',
        baseUrl: 'http://188.166.177.25/',
        notice: '***ห้ามลืมเด็ดขาด ผู้ปฎิบัติงานจะต้องกำหนดรอบส่งเงินทุกวัน*** ***การรับชำระเงินผิดพลาด  ตัดยอดส่งเงินผิดจะต้องชดเงินตัวเองออกไปก่อนนะเพราะไม่สามารถแก้ไขได้*** ***การเพื่มหรือยกเลิกสมาชิกจะต้องทำหลังจากเปลี่ยนรอบบิลเท่านั่น***'
      }
    },
    watch: {
      radioBtn: {
        handler(val) {
          if (val === '01') {
            this.disable01 = false;
            this.disable02 = true;
            this.disable03 = true;
            this.villageNo = '';
            this.telNumber = '';
          }
          if (val === '02') {
            this.disable01 = true;
            this.disable02 = false;
            this.disable03 = true;
            this.inputNumber = '';
            this.telNumber = '';
          }
          if (val === '03') {
            this.disable01 = true;
            this.disable02 = true;
            this.disable03 = false;
            this.inputNumber = '';
            this.villageNo = '';
          }
        },
        deep: true
      },
    },
    computed: {
      org() {
        return this.$store.state.config.item;
      },
      paybys() {
        let options = [];
        this.$store.state.payby.items.forEach(function (item) {
          options.push({
            'label': item.pb02,
            'value': item.pb01
          });
        });
        return options;
      },
      // invoiceData() {
      //   const date = this.$store.state.invoiceDate.items[0];
      //   const formatDate = moment(date).format('DD/MM/YYYY')
      //   return formatDate;
      // }
    },
    methods: {
      async init() {
        if (localStorage.getItem('receive_no') != null)
          this.number = localStorage.getItem('receive_no');
        if (localStorage.getItem('paper_x') != null)
          this.x = localStorage.getItem('paper_x');
        if (localStorage.getItem('paper_y') != null)
          this.y = localStorage.getItem('paper_y');
        this.selectedPayby = {
          'label': await this.$store.state.payby.items[0].pb02,
          'value': await this.$store.state.payby.items[0].pb01
        };
      },
      savePaperSetup() {
        localStorage.setItem('paper_x', this.x);
        localStorage.setItem('paper_y', this.y);
      },
      async createNumber() {
        if (this.number === '') {
          this.warningDialog('กรุณากรอกเลขที่ใบเสร็จ');
          return;
        }
        if (this.paidList.length === 0) {
          this.failDialog('ไม่มีรายการที่สามารถสร้างใบเสร็จได้');
          return;
        }
        let count = 0;
        for (let i = 0; i < this.paidList.length; i++) {
          let num = parseInt(this.number) + i;
          this.paidList[i].B02 = num.toString();
          const body = {
            _id: this.paidList[i]._id,
            B02: num.toString()
          }
          count++;
          let array = [];
          await this.$store.dispatch("bookLists/updateItemNumber", body).then((res) => {
            if (res.status !== 200) {
              this.failDialog(text);
              return;
            } else {
              for (let i = 0; i < this.paidList.length; i++) {
                let data = this.paidList[i];
                if (data._id === body._id) {
                  array.push(data)
                }
              }
              if (i === this.paidList.length) {
                this.paidList = array;
              }

            }
          }).catch((error) => {
            this.failDialog('สร้างเลขที่ใบเสร็จไม่สำเร็จ');
            return;
          });
        }
        if (count === 0) {
          this.failDialog('สร้างเลขที่ใบเสร็จไม่สำเร็จ');
        } else {
          this.number = parseInt(this.paidList[this.paidList.length - 1].B02) + 1
          localStorage.setItem('receive_no', this.number);
          this.createReceiveSuccess();
        }
      },
      onKeydown(e) {
        if (e.keyCode == 13 || e.which == 13) { //Enter keycode
          this.submit()
        }
      },
      typeOfNumber(value) {
        const data = parseFloat(value).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})
        return data;
      },
      splitDate(value, type) {
        const date = value.split('-');
        if (type === 'month') {
          return date[0];
        } else {
          return date[1];
        }
      },
      setMember(item) {
        this.indexMember = item.index;
        this.setUserData(this.userAll, item.index);
      },
      calculateBA(index) {
        if (this.checkBox[index]) {
          this.sumCheckbox = parseFloat(this.sumCheckbox.toString().replace(",","")) + parseFloat(this.sumTable[index].toString().replace(",",""));
        } else {
          this.sumCheckbox = parseFloat(this.sumCheckbox.toString().replace(",","")) - parseFloat(this.sumTable[index].toString().replace(",",""));
        }
        this.sumCheckbox = parseFloat(this.sumCheckbox).toLocaleString("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        });
        console.log('sumCheckbox '+this.sumCheckbox)
      },
      submit() {
        this.paidList = []
        if (this.radioBtn === '01') {
          if (this.inputNumber === '') {
            this.warningDialog('กรุณากรอกเลขทะเบียนผู้ใช้น้ำ');
            return;
          }
          const user = {M01: this.inputNumber};
          this.getUser(user);
        } else if (this.radioBtn === '02') {
          if (this.villageNo === '') {
            this.warningDialog('กรุณากรอกบ้านเลขที่');
            return;
          }
          const user = {M05: this.villageNo};
          this.getUser(user);
        } else {
          if (this.telNumber === '') {
            this.warningDialog('กรุณากรอกเบอร์โทร');
            return;
          }
          const user = {M07: this.telNumber};
          this.getUser(user);
        }
      },
      async submitPayment() {
        let book = [];
        let noBook = true;
        for (let i = 0; i < this.checkBox.length; i++) {
          if (this.checkBox[i]) {
            book.push(this.bookLists[i]._id);
            noBook = false;
          }
        }
        if (noBook) {
          this.warningDialog('กรุณาเลือกบิลอย่างน้อย 1 บิล');
          return;
        }
        // this.$vs.loading();
        const user = JSON.parse(sessionStorage.getItem('vuex'));
        const staffId = user.owner.user.user._id;
        const body = {};
        body.memberId = this.member._id;
        body.staffId = staffId;
        body.list = book;
        body.payment_type_id = this.selectedPayby.value,
          body.payment = this.money;
        body.payment_number = '';
        if (this.setPaybys.value === '03' || this.setPaybys.value === '04') {
          body.payment_number = this.bankNumber;
        }
        localStorage.setItem('memberPayment', JSON.stringify(this.selectedMember));
        await this.$validator.validateAll().then(result => {
          this.$store.dispatch("bookLists/updateItemForPayment", body).then((res) => {
            if(res.data.success === false){
              this.failDialog('กรุณากำหนดรอบส่งเงินก่อนรับชำระเงิน');
            }else{
              if (res.status === 200) {
              let response = res.data;
              let array = [];
              for (let i = 0; i < response.length; i++) {
                let data = response[i];
                data.B24 = this.formatDate(response[i].B24)
                data.B17 = this.formatTextDate(response[i].B17)
                data.B19 = this.formatTextDate(response[i].B19)
                data.Org01 = this.org.Org01
                data.Org02 = this.org.Org02
                data.Org03 = this.org.Org03
                data.Org04 = this.org.Org04
                data.Org05 = this.org.Org05
                data.sum = formatNumber2Digit(parseFloat(response[i].BA11) + parseFloat(response[i].BA12) + parseFloat(response[i].BA13) + parseFloat(response[i].BA14) + parseFloat(response[i].BA15));
                array.push(data)
              }
              this.paidList = array;
              localStorage.setItem('bookListsPayment', JSON.stringify(res.data));
              let checkBook = [];
              let box = [];
              let sum = [];
              for (let i = 0; i < this.checkBox.length; i++) {
                if (!this.checkBox[i]) {
                  checkBook.push(this.bookLists[i]);
                  sum.push(this.sumTable[i]);
                  box[i] = false;
                }
              }
              this.sumCheckbox = 0;
              this.sumTable = sum;
              this.checkBox = box;
              this.bookLists = checkBook;
              //this.$vs.loading.close();
              this.successDialog();
            } else {
              this.failDialog('บันทึกการชำระเงินไม่สำเร็จ');
              //this.$vs.loading.close();
            }
            }
          }).catch((error) => {
            this.failDialog('บันทึกการชำระเงินไม่สำเร็จ');
            //this.$vs.loading.close();
          });
        });
      },
      setPaybys(item) {
        if (item.value === '03' || item.value === '04') {
          this.disableBank = false;
        } else {
          this.disableBank = true;
        }
      },
      async setUserData(member, index) {
        // this.$vs.loading();
        // let type = '';
        // if (member[index].M17 !== undefined && member[index].M17 !== null && member[index].M17 !== '') {
        //   type = this.payBySelect[parseInt(member[index].M17)];
        // }
        this.member = {
          _id: member[index]._id,
          area: member[index].M02,
          name: member[index].M04,
          meter: member[index].M27,
          member_no: member[index].M01,
          meter_no: member[index].M27,
          address: member[index].M05,
          road: member[index].M06,
          telephone: member[index].M07,
          // type: type
        };
        const bookLists = {B03: member[index].M01, B10: '0', sortBy: 'B01:asc'};
        this.getBookLists(bookLists);
        const url = await this.$store.state.config.item.report_image_url;
        if (url !== '' && url !== undefined && url !== null) {
          this.imgSignBase64 = await this.getDataUri(this.baseUrl + url);
        }
        const logoUrl = await this.$store.state.config.item.logo_image_url;
        if (logoUrl !== '' && logoUrl !== undefined && logoUrl !== null) {
          this.imgLogoBase64 = await this.getDataUri(this.baseUrl + logoUrl);
        }
      },
      getDataUri(url) {
        return new Promise(resolve => {
          var image = new Image();
          image.setAttribute('crossOrigin', 'anonymous');
          image.onload = function () {
            var canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(this, 0, 0);
            var dataURL = canvas.toDataURL("image/png");
            resolve(canvas.toDataURL("image/png"));
          };
          image.src = url;
        })
      },
      async getUser(payload) {
        // this.$vs.loading();
        await this.$store.dispatch("member/fetchMemberListByFilter", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            const member = res.data;
            this.userAll = res.data;
            let options = [];
            for (let i = 0; i < member.length; i++) {
              options.push({
                'label': member[i].M04 + ' ' + member[i].M05 + ' ' + member[i].soi + ' ' + member[i].road,
                'value': member[i].M01,
                'index': i,
                'data': member[i]
              });
            }
            this.memberOpt = options;
            this.selectedMember = {
              label: options[0].label,
              value: options[0].value,
              data: options[0].data
            };
            this.setUserData(member, 0);
          } else {
            this.noDataMember();
            //this.$vs.loading.close();
          }
        }).catch((error) => {
          this.noDataMember();
          //this.$vs.loading.close();
        });
      },
      formatDate(text) {
        try {
          const date = moment(text).format('DD/MM/YYYY');
          const dateSplit = date.split('/');
          const year = parseInt(dateSplit[2]) + 543;
          return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
        }catch (e) {
          return text
        }
      },
      formatTextDate(text) {
        try {
          if (text !== '' && text !== undefined) {
            const date = text.split('/');
            let year = ''
            if (date[2].toString().length === 11)
              year = parseInt(date[2]) + 2543;
            else
              year = parseInt(date[2]) + 543;
            return date[0] + '/' + date[1] + '/' + year;
          } else {
            return '-'
          }
        } catch (e) {
          return text
        }

      },
      async getBookLists(payload) {
        await this.$store.dispatch("bookLists/getBookListsForPayment", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            this.disableBank = true;
            const book = res.data;
            let arr = [];
            let sumRead = 0;
            let bookListArr = [];
            let box = [];
            let count = 0;
            for (let i = 0; i < book.length; i++) {
              let BA11 = 0;
              let BA12 = 0;
              let BA13 = 0;
              let BA14 = 0;
              let BA15 = 0;
              if (book[i].B22 !== 'NaN' && book[i].BA11 !== 'NaN' && book[i].BA12 !== 'NaN' && book[i].BA13 !== 'NaN') {
                if (book[i].BA11 !== undefined && book[i].BA11 !== null && book[i].BA11 !== '') {
                  BA11 = parseFloat(book[i].BA11);
                }
                if (book[i].BA12 !== undefined && book[i].BA12 !== null && book[i].BA12 !== '') {
                  BA12 = parseFloat(book[i].BA12);
                }
                if (book[i].BA13 !== undefined && book[i].BA13 !== null && book[i].BA13 !== '') {
                  BA13 = parseFloat(book[i].BA13);
                }
                if (book[i].BA14 !== undefined && book[i].BA14 !== null && book[i].BA14 !== '') {
                  BA14 = parseFloat(book[i].BA14);
                }
                if (book[i].BA15 !== undefined && book[i].BA15 !== null && book[i].BA15 !== '') {
                  BA15 = parseFloat(book[i].BA15);
                }
                const sum = BA11 + BA12 + BA13 + BA14 + BA15;
                sumRead += sum;
                arr.push(sum);
                box[count] = true;
                bookListArr.push(book[i]);
                count++;
              }
            }
            this.bookLists = bookListArr;
            this.checkBox = box;
            this.sumCheckbox = parseFloat(sumRead).toLocaleString("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            });
            this.sumTable = arr;
          } else {
            this.noDataBookLists();
          }
          //this.$vs.loading.close();
        }).catch((error) => {
          this.noDataBookLists();
          //this.$vs.loading.close();
        });
      },
      noDataMember() {
        this.memberOpt = [];
        this.selectedMember = {
          label: 'ไม่พบข้อมูล',
          value: '',
          data: ''
        };
        this.member = {
          _id: '',
          name: '',
          meter: '',
          address: '',
          road: '',
          telephone: ''
        };
      },
      noDataBookLists() {
        this.bookLists = [];
        this.sumTable = [];
        this.sum = '';
        this.money = '';
        this.change = '';
        this.typePayment = '';
      },
      warningDialog(message) {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: message,
          acceptText: 'ปิด'
        })
      },
      route() {
        this.$router.push('/user').catch(() => {
        })
      },
      failDialog(message) {
        this.$vs.dialog({
          type: 'alert',
          color: 'danger',
          title: `ผิดพลาด`,
          text: message,
          acceptText: 'ปิด'
        })
      },
      successDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'success',
          title: `สำเร็จ`,
          text: 'บันทึกการชำระเงินสำเร็จ',
          acceptText: 'ปิด',
        })
      },
      createReceiveSuccess() {
        this.$vs.dialog({
          type: 'alert',
          color: 'success',
          title: `สำเร็จ`,
          text: 'สร้างเลขที่ใบเสร็จสำเร็จ',
          acceptText: 'ปิด',
        })
      }
    },
    async created() {
      // this.$vs.loading();
      localStorage.removeItem('bookListsPayment');
      localStorage.removeItem('memberPayment');
      // if (!moduleInvoiceDate.isRegistered) {
      //   this.$store.registerModule('invoiceDate', moduleInvoiceDate)
      //   moduleInvoiceDate.isRegistered = true
      // }
      if (!moduleMember.isRegistered) {
        this.$store.registerModule('member', moduleMember);
        moduleMember.isRegistered = true;
      }
      if (!moduleBookList.isRegistered) {
        this.$store.registerModule('bookLists', moduleBookList);
        moduleBookList.isRegistered = true;
      }
      if (!modulePayby.isRegistered) {
        this.$store.registerModule('payby', modulePayby);
        modulePayby.isRegistered = true
      }
      if (!moduleConfig.isRegistered) {
        this.$store.registerModule('config', moduleConfig)
        moduleConfig.isRegistered = true
      }
      await this.$store.dispatch("config/fetchDataItem")
      await this.$store.dispatch("payby/fetchDataListItems");
      // await this.$store.dispatch("invoiceDate/fetchDataListItems");
      //this.$vs.loading.close();
      this.init();
    }
  }

</script>

<style lang="scss">
  .table-custom .vs-con-tbody .vs-table--tbody-table .vs-table--thead th {
    background: #8794a2;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
  }
</style>
